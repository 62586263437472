<template>
  

     
      <div class="app flex-row align-items-center">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-md-8">
                      <div class="card-group mb-0">
                            <div class="card card-inverse card-primary py-5 d-md-down-none" style="width:44%">
                              <div class="card-block text-center">
                                  <div>
                          <div class="card p-4">
                              
                                  <div class='alert alert-danger' v-if="is_error">
                                          <ul>
                                    
                                              <li>
                                                  Tên đăng nhập hay mật khẩu không chính xác.
                                              </li>
                                        
                                      </ul>
                                  </div>
                              
                              <form method="post" id="my_form" @submit="onSubmit">
                              <div class="card-block">

                                  <h1>Đăng nhập</h1>
                                  <p class="text-muted">Đăng nhập </p>
                                  <div class="input-group mb-3">
                                      <span class="input-group-addon"><i class="icon-user"></i>
                                      </span>
                                      <input type="text" class="form-control" name='username' placeholder="Username">
                                  </div>
                                  <div class="input-group mb-4">
                                      <span class="input-group-addon"><i class="icon-lock"></i>
                                      </span>
                                      <input type="password" name='password' class="form-control" placeholder="Password">
                                  </div>
                                  <div class="row">
                                      <div class="col-6">
                                          <button type="submit" class="btn btn-primary px-4">Đăng nhập</button>
                                      </div>
                                      <div class="col-6 text-right">
                                        
                                      </div>
                                  </div>
                              </div>
                              </form>
                          </div>
                        
                                      
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      

      </div>


</template>


<script>
  import axios from 'axios';
  export default{
    data(){
      return {
        is_error:false
      }
    },
    methods:{
      onSubmit(event){
        event.preventDefault();


        this.$session.start();

          let myForm = document.getElementById("my_form");
          let formData = new FormData(myForm);

         

          axios
              .get(window.my_api + "api/user/get-checklogin",{params:{username:formData.get("username"),password:formData.get("password")}})
              .then((res) => {
                if (res.status == 200) {
                    if(res.data.successed){
                         this.$session.set("isLogin", res.data.data );
                          localStorage.mylogin = JSON.stringify(res.data.data);
                        this.$router.push({name:"admin.index"}, null, { shallow: true });



                    }else{
                        this.is_error=true;
                    }
                   
                    //   if(formData.get("username")=='admin' && formData.get("password")=='admin@123'){
                    //     this.$session.set("isLogin", formData.get("username") );
                    //     this.$router.push({name:"admin.index"});
                    // }else{
                    //   this.is_error=true;
                    // }
                } else {
                  this.is_error=true;
                }
              });

        

        return false;
      }
    }
  }
</script>